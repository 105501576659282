import React, { createContext, ReactNode, useEffect, useState } from "react"
import { ActionDTO, FilterConfigDTO, TimespanSettingsDTO } from "generated/models"
import { debounce } from "throttle-debounce"
import FilterComponentUtil from "domain/filter/component/FilterComponentUtil"
import ConditionClauseService from "shared/service/conditionClauseService"
import { FilterState } from "domain/types"

type ToolsContextProviderProperties = {
    filters?: FilterConfigDTO[]
    timespanSettings?: TimespanSettingsDTO
    children?: ReactNode
}

type ToolsContextProperties = ToolsContextProviderProperties & {
    filterStates?: FilterState[]
    filterOnChange?: (filterIdentifier: string, value: string | number | string[] | number[]) => void
    updateTimespanSettings?: (timespanSettings?: TimespanSettingsDTO) => void
    searchTerm?: string
    updateSearchTerm?: (searchTerm?: string) => void
    downloadProcessing?: boolean
    updateDownloadProcessing?: (downloadProcessing: boolean) => void
    actionState?: ActionState
    updateActionState?: (action: ActionDTO) => void
    contextInitialized?: boolean
}

export const ToolsContext = createContext<ToolsContextProperties>({})

type FilterConfigState = {
    filters?: FilterConfigDTO[]
}
type FilterStateState = {
    filterStates?: FilterState[]
}

type TimespanSettingsState = {
    timespanSettings: TimespanSettingsDTO
}
type ActionState = {
    action: ActionDTO
    trigger: boolean
}

/**
 * This context provider manages data for download, search, filters and the time span.
 * Children elements (such as GenericDataGridWidget, FilterContainer, TimeSpanElement, GenericDataGridSearchForm and PanelToolbarComponent)
 * reacts with the help of useEffect to the changes in the context.
 *
 * @param props
 * @constructor
 */
export const ToolsContextProvider: React.FC<ToolsContextProviderProperties> = (
    props: ToolsContextProviderProperties,
): JSX.Element => {
    const [initialized, setInitialized] = useState(true)
    const [actionState, setActionState] = useState<ActionState | undefined>(undefined)
    const [downloadProcessing, setDownloadProcessing] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")
    const [filters, setFilters] = useState<FilterConfigState>({ filters: props.filters })
    const [filterStates, setFilterStates] = useState<FilterStateState>({
        filterStates: FilterComponentUtil.createInitialFilterStates(props.filters),
    })
    const [timespanSettingsState, setTimespanSettingsState] = useState<TimespanSettingsState>({
        timespanSettings: props.timespanSettings,
    })

    const clearState = () => {
        setInitialized(false)
    }

    useEffect(() => {
        return clearState
    }, [props])

    useEffect(() => {
        setTimespanSettingsState({ timespanSettings: props.timespanSettings })
    }, [props.timespanSettings])

    useEffect(() => {
        setInitialized(true)
        setSearchTerm("")
        setFilters({ filters: props.filters })
        setFilterStates({ filterStates: FilterComponentUtil.createInitialFilterStates(props.filters) })
    }, [props.filters])

    /**
     * Finds the dependent filters and sets additionalFilters to them so that they react to the change
     *
     * @param filterIdentifier
     * @param value
     */
    const updateDependentFilters = (filterIdentifier: string, value: string | number | string[] | number[]) => {
        const changedFilterConfig: FilterConfigDTO = filters.filters.find((filter) => {
            return FilterComponentUtil.getFilterFormValueColumn(filter) === filterIdentifier
        })
        const changedFilterState: FilterState = {
            selectFormElement: changedFilterConfig.selectFormElement,
            value: value,
        }

        const conditionClauseDTO = ConditionClauseService.buildFilterQuery([changedFilterState])

        let dependentFilterFound = false
        const updateFilters = filters.filters.map((dependentFilter) => {
            const filterDependsOnTheChangedFilter = FilterComponentUtil.getFilterDependsOn(dependentFilter)?.some(
                (entry) => entry.filterIdentifier === filterIdentifier,
            )

            if (filterDependsOnTheChangedFilter) {
                dependentFilterFound = true
                dependentFilter.selectFormElement.additionalFilters = conditionClauseDTO
            }

            return dependentFilter
        })

        if (dependentFilterFound) {
            setFilters({ filters: updateFilters })
        }
    }

    const context = {
        timespanSettings: timespanSettingsState.timespanSettings,
        filters: filters.filters,
        filterStates: filterStates.filterStates,
        filterOnChange: (filterIdentifier: string, value: string | number | string[] | number[]) => {
            const updatedFilterStates = filterStates.filterStates.map((filterState) => {
                if (FilterComponentUtil.getFilterFormValueColumn(filterState) === filterIdentifier) {
                    filterState.value = value
                }

                return filterState
            })

            setFilterStates({ filterStates: updatedFilterStates })
            updateDependentFilters(filterIdentifier, value)
        },
        updateTimespanSettings: (timespanSettings?: TimespanSettingsDTO) => {
            setTimespanSettingsState((prev) => {
                return { ...prev, timespanSettings: timespanSettings }
            })
        },
        searchTerm: searchTerm,
        updateSearchTerm: debounce(500, setSearchTerm),
        downloadProcessing: downloadProcessing,
        updateDownloadProcessing: setDownloadProcessing,
        actionState: actionState,
        updateActionState: (action: ActionDTO) => {
            setActionState((prev) => {
                return {
                    action: action,
                    trigger: prev == undefined ? true : !prev.trigger,
                }
            })
        },
        contextInitialized: initialized,
    } as ToolsContextProperties

    return <ToolsContext.Provider value={context}>{props.children}</ToolsContext.Provider>
}
