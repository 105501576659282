import { Autocomplete, TextField } from "@mui/material"
import React from "react"
import FormControl from "@mui/material/FormControl"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { ConversionStateSelection } from "domain/ConversionList/domain/conversionState"

const conversionStatusOptions: ConversionStateSelection[] = ["open", "valid", "canceled", "changed"]

export const ConversionStateFilter = () => {
    const conversionStatusSelection = ConversionListContextSelectors.useConversionStateSelection()
    const updateConversionStatusSelection = ConversionListContextSelectors.useUpdateConversionStateSelection()

    const handleChange = (_: React.SyntheticEvent, value: ConversionStateSelection | null) => {
        updateConversionStatusSelection(value ?? "")
    }

    return (
        <FormControl sx={{ mt: 4, minWidth: 120 }}>
            <Autocomplete
                value={conversionStatusSelection}
                sx={{ width: 300, display: "inline-block", maxWidth: "100%", mb: 1 }}
                size="small"
                options={conversionStatusOptions}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        InputProps={{
                            ...params.InputProps,
                        }}
                        label="Select Conversion State"
                    />
                )}
            />
        </FormControl>
    )
}
