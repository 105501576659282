import React from "react"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import {
    Box,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material"
import { makeCustomerJourneyColumns } from "domain/ConversionList/domain/domain"
import DimensionService from "domain/dimension/service/DimensionService"
import { DRAWER_WIDTH_LARGE_SCREEN, DRAWER_WIDTH_SMALL_SCREEN } from "layout/MainLayout/constants"

export type CustomerJourneyPanelProps = Readonly<{
    transactionUid: string
    onContextMenu?: (event: React.MouseEvent) => void
}>

export const CustomerJourneyPanel = ({ transactionUid, onContextMenu }: CustomerJourneyPanelProps) => {
    const appContext = ConversionListContextSelectors.useAppContext()
    const queryConfig = ConversionListContextSelectors.useCurrentQueryConfig()
    const customerJourneyPageConfig = ConversionListContextSelectors.useLoadCustomerJourneyPageConfigQuery({
        appContext,
    })
    const customerJourney = ConversionListContextSelectors.useLoadCustomerJourneyQuery({
        queryConfig,
        transactionUid,
    })

    if (customerJourneyPageConfig.isPending || customerJourney.isPending) {
        return (
            <CircularProgress
                className="cjLoadingIndicator"
                size={24}
                sx={{
                    position: "relative",
                    left: {
                        // The navbar widths depends on the screen size, so we have to copy its breakpoints
                        xs: `calc(50vw + 24px + 30px - ${DRAWER_WIDTH_SMALL_SCREEN})`,
                        lg: `calc(50vw + 24px + 30px - ${DRAWER_WIDTH_LARGE_SCREEN})`,
                    },
                    m: 1,
                }}
            />
        )
    }

    if (customerJourneyPageConfig.isError || customerJourney.isError) {
        return <Box sx={{ m: 1 }}>There was an error while fetching your data. Please try again later.</Box>
    }

    const customerJourneyColumns = makeCustomerJourneyColumns(customerJourneyPageConfig.data.elementConfig)

    return (
        <div>
            <TableContainer component={Paper}>
                <Table onContextMenu={onContextMenu}>
                    <TableHead>
                        <TableRow>
                            {customerJourneyColumns.fieldNames.map((fieldName) => (
                                <TableCell key={fieldName}>
                                    {
                                        customerJourneyColumns.columnDetails[fieldName]!.columnConfigDTO
                                            .gridColumnProperties.columnHeader
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {customerJourney.data.dataSet.rows.map((row, idx) => (
                            <TableRow key={idx}>
                                {customerJourneyColumns.fieldNames.map((fieldName) => {
                                    const identifier = DimensionService.recognizeDimensionField(fieldName).identifier
                                    const cellContent = row[identifier]?.name || row[identifier]?.value || ""
                                    return <TableCell key={`${idx}_${fieldName}`}>{cellContent}</TableCell>
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
